<template>
  <v-container fill-height fluid justify-center>
    <div class="d-flex flex-column align-center">
      <div class="text-md-h2 text-h4 primary--text font-weight-bold">
        (ノ﹏ヽ)
      </div>
      <div class="my-3 text-md-h6 grey--text font-weight-regular">
        Something went wrong. It may automatically fix itself with a refresh.
      </div>
      <v-btn
        depressed
        color="primary"
        class="text-capitalize"
        @click="refresh"
        >Refresh</v-btn
      >
    </div>
  </v-container>
</template>

<script>
export default {
  data: () => ({}),
  created() {
    //
  },
  methods:{
    refresh(){
      window.location.href = window.location.origin
    }
  }
};
</script>
